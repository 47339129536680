.page-style {
  background-image: "url(background_hd.jpg)";
  height: 100vh;
  background-size: 'cover';
  background-repeat: no-repeat;
}

.home-banner{
  min-height: 300px;
  display: flex;
  align-items: center;
}

.block-title {
  text-align: center;
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.main-container {
  min-height: 78vh;
  max-height: 78vh;
}

.page-card {
  opacity: 0.9;
  color: white!important;
  background-color: #54549b!important;
}

.top-2 {
  margin-top: 2vh;
}

.card-link {
  text-decoration: none;
}

.card-h-1 {
  min-height: 22vh;
}

.card-hover:hover {
  background-color: #3a3a6d;
  opacity: 1;
  box-shadow: 0px 0px 5px 3px lightblue;
}

.page-footer {
  min-height: 100px;

}

.nav-link {
  font-size: 1.5em!important;
}

a.footer-link {
  color: white;
}

